<script lang="ts" setup>
import { EmitsEnum } from '~~/src/constants/emits'
import { useAccommodationsStore } from '~~/src/store/accommodations'

const props = withDefaults(
	defineProps<{
		accommodationId: string
		hideVirtualRooms?: boolean
		hideIndexedRooms?: boolean
		disableIndexedRooms?: boolean
		showNumUnitsZero?: boolean
		compact?: boolean
		testIds?: { [key: string]: string }
	}>(),
	{
		hideVirtualRooms: false,
		hideIndexedRooms: false,
		disableIndexedRooms: false,
		showNumUnitsZero: false,
		compact: false,
	}
)
const { accommodationId, hideVirtualRooms, hideIndexedRooms, disableIndexedRooms, showNumUnitsZero } = toRefs(props)
const emit = defineEmits([EmitsEnum.Change])

const externalSelectedId = defineModel<number>('selectedId', { required: false })
const accommodationStore = useAccommodationsStore()

const roomTypes = computed(() => {
	let availableRooms =
		accommodationStore.roomTypes.filter((room) => room.accommodationId == accommodationId.value) || []

	if (!showNumUnitsZero?.value) {
		availableRooms = availableRooms.filter((el) => el.numUnits > 0)
	}

	if (hideVirtualRooms?.value) {
		availableRooms = availableRooms.filter((el) => !el.isVirtual)
	}

	if (hideIndexedRooms?.value) {
		availableRooms = availableRooms.filter((el) => el.isIndexed != true)
	}

	return availableRooms
})

const getDropdownEntries = computed(() =>
	roomTypes.value.map((entry) => ({
		label: entry.name,
		value: entry.id,
		disabled: disableIndexedRooms.value && entry.isIndexed,
	}))
)

const selectedId = computed({
	get: () => externalSelectedId.value,
	set: (val: number) => {
		emit(EmitsEnum.Change, val)
	},
})
</script>

<template>
	<SpInputSelect :items="getDropdownEntries" v-model="selectedId" :test-ids="testIds"></SpInputSelect>
</template>
